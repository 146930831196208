import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ede973dc = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _4e3dcee2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _21cf9e8d = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _1ee20033 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _069c2c2e = () => interopDefault(import('../pages/compare/index.vue' /* webpackChunkName: "pages/compare/index" */))
const _13c892d8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2a25581c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _7405fa59 = () => interopDefault(import('../pages/reset-password-again/index.vue' /* webpackChunkName: "pages/reset-password-again/index" */))
const _6fa9b97c = () => interopDefault(import('../pages/product/catalog/index.vue' /* webpackChunkName: "pages/product/catalog/index" */))
const _95094fc6 = () => interopDefault(import('../pages/profile/company.vue' /* webpackChunkName: "pages/profile/company" */))
const _11cf46f7 = () => interopDefault(import('../pages/profile/favorites.vue' /* webpackChunkName: "pages/profile/favorites" */))
const _1eaad366 = () => interopDefault(import('../pages/profile/loyalty.vue' /* webpackChunkName: "pages/profile/loyalty" */))
const _ecff9590 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _6a5289f2 = () => interopDefault(import('../pages/profile/promocodes.vue' /* webpackChunkName: "pages/profile/promocodes" */))
const _5728ba44 = () => interopDefault(import('../pages/profile/refunds/index.vue' /* webpackChunkName: "pages/profile/refunds/index" */))
const _25e310bb = () => interopDefault(import('../pages/profile/reviews.vue' /* webpackChunkName: "pages/profile/reviews" */))
const _2b3c64f2 = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _53cc9212 = () => interopDefault(import('../pages/room/catalog/index.vue' /* webpackChunkName: "pages/room/catalog/index" */))
const _1c391d4b = () => interopDefault(import('../pages/search/not-found.vue' /* webpackChunkName: "pages/search/not-found" */))
const _5e2af34a = () => interopDefault(import('../pages/checkout/success/_order.vue' /* webpackChunkName: "pages/checkout/success/_order" */))
const _401bb606 = () => interopDefault(import('../pages/product/catalog/_slug/index.vue' /* webpackChunkName: "pages/product/catalog/_slug/index" */))
const _5eb4441c = () => interopDefault(import('../pages/profile/orders/_order/index.vue' /* webpackChunkName: "pages/profile/orders/_order/index" */))
const _31d09f23 = () => interopDefault(import('../pages/profile/refunds/_refund.vue' /* webpackChunkName: "pages/profile/refunds/_refund" */))
const _575937cd = () => interopDefault(import('../pages/room/catalog/_slug/index.vue' /* webpackChunkName: "pages/room/catalog/_slug/index" */))
const _3021aac4 = () => interopDefault(import('../pages/profile/orders/_order/refund.vue' /* webpackChunkName: "pages/profile/orders/_order/refund" */))
const _14dc2c8a = () => interopDefault(import('../pages/blog/_slug/index.vue' /* webpackChunkName: "pages/blog/_slug/index" */))
const _54c8e4e9 = () => interopDefault(import('../pages/catalog/_query.vue' /* webpackChunkName: "pages/catalog/_query" */))
const _68b9fc2a = () => interopDefault(import('../pages/contacts/_id/index.vue' /* webpackChunkName: "pages/contacts/_id/index" */))
const _56c436a3 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _4818287a = () => interopDefault(import('../pages/room/_slug/index.vue' /* webpackChunkName: "pages/room/_slug/index" */))
const _edf3d1d4 = () => interopDefault(import('../pages/search/_query.vue' /* webpackChunkName: "pages/search/_query" */))
const _b3e4f054 = () => interopDefault(import('../pages/set/_id/index.vue' /* webpackChunkName: "pages/set/_id/index" */))
const _1211f190 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _ede973dc,
    name: "auth___pl"
  }, {
    path: "/blog",
    component: _4e3dcee2,
    name: "blog___pl"
  }, {
    path: "/cart",
    component: _21cf9e8d,
    name: "cart___pl"
  }, {
    path: "/checkout",
    component: _1ee20033,
    name: "checkout___pl"
  }, {
    path: "/compare",
    component: _069c2c2e,
    name: "compare___pl"
  }, {
    path: "/en",
    component: _13c892d8,
    name: "index___en"
  }, {
    path: "/profile",
    component: _2a25581c,
    name: "profile___pl"
  }, {
    path: "/reset-password-again",
    component: _7405fa59,
    name: "reset-password-again___pl"
  }, {
    path: "/en/auth",
    component: _ede973dc,
    name: "auth___en"
  }, {
    path: "/en/blog",
    component: _4e3dcee2,
    name: "blog___en"
  }, {
    path: "/en/cart",
    component: _21cf9e8d,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _1ee20033,
    name: "checkout___en"
  }, {
    path: "/en/compare",
    component: _069c2c2e,
    name: "compare___en"
  }, {
    path: "/en/profile",
    component: _2a25581c,
    name: "profile___en"
  }, {
    path: "/en/reset-password-again",
    component: _7405fa59,
    name: "reset-password-again___en"
  }, {
    path: "/product/catalog",
    component: _6fa9b97c,
    name: "product-catalog___pl"
  }, {
    path: "/profile/company",
    component: _95094fc6,
    name: "profile-company___pl"
  }, {
    path: "/profile/favorites",
    component: _11cf46f7,
    name: "profile-favorites___pl"
  }, {
    path: "/profile/loyalty",
    component: _1eaad366,
    name: "profile-loyalty___pl"
  }, {
    path: "/profile/orders",
    component: _ecff9590,
    name: "profile-orders___pl"
  }, {
    path: "/profile/promocodes",
    component: _6a5289f2,
    name: "profile-promocodes___pl"
  }, {
    path: "/profile/refunds",
    component: _5728ba44,
    name: "profile-refunds___pl"
  }, {
    path: "/profile/reviews",
    component: _25e310bb,
    name: "profile-reviews___pl"
  }, {
    path: "/profile/tickets",
    component: _2b3c64f2,
    name: "profile-tickets___pl"
  }, {
    path: "/room/catalog",
    component: _53cc9212,
    name: "room-catalog___pl"
  }, {
    path: "/search/not-found",
    component: _1c391d4b,
    name: "search-not-found___pl"
  }, {
    path: "/en/product/catalog",
    component: _6fa9b97c,
    name: "product-catalog___en"
  }, {
    path: "/en/profile/company",
    component: _95094fc6,
    name: "profile-company___en"
  }, {
    path: "/en/profile/favorites",
    component: _11cf46f7,
    name: "profile-favorites___en"
  }, {
    path: "/en/profile/loyalty",
    component: _1eaad366,
    name: "profile-loyalty___en"
  }, {
    path: "/en/profile/orders",
    component: _ecff9590,
    name: "profile-orders___en"
  }, {
    path: "/en/profile/promocodes",
    component: _6a5289f2,
    name: "profile-promocodes___en"
  }, {
    path: "/en/profile/refunds",
    component: _5728ba44,
    name: "profile-refunds___en"
  }, {
    path: "/en/profile/reviews",
    component: _25e310bb,
    name: "profile-reviews___en"
  }, {
    path: "/en/profile/tickets",
    component: _2b3c64f2,
    name: "profile-tickets___en"
  }, {
    path: "/en/room/catalog",
    component: _53cc9212,
    name: "room-catalog___en"
  }, {
    path: "/en/search/not-found",
    component: _1c391d4b,
    name: "search-not-found___en"
  }, {
    path: "/en/checkout/success/:order?",
    component: _5e2af34a,
    name: "checkout-success-order___en"
  }, {
    path: "/en/product/catalog/:slug",
    component: _401bb606,
    name: "product-catalog-slug___en"
  }, {
    path: "/en/profile/orders/:order",
    component: _5eb4441c,
    name: "profile-orders-order___en"
  }, {
    path: "/en/profile/refunds/:refund",
    component: _31d09f23,
    name: "profile-refunds-refund___en"
  }, {
    path: "/en/room/catalog/:slug",
    component: _575937cd,
    name: "room-catalog-slug___en"
  }, {
    path: "/en/profile/orders/:order/refund",
    component: _3021aac4,
    name: "profile-orders-order-refund___en"
  }, {
    path: "/checkout/success/:order?",
    component: _5e2af34a,
    name: "checkout-success-order___pl"
  }, {
    path: "/en/blog/:slug",
    component: _14dc2c8a,
    name: "blog-slug___en"
  }, {
    path: "/en/catalog/:query?",
    component: _54c8e4e9,
    name: "catalog-query___en"
  }, {
    path: "/en/contacts/:id",
    component: _68b9fc2a,
    name: "contacts-id___en"
  }, {
    path: "/en/product/:id",
    component: _56c436a3,
    name: "product-id___en"
  }, {
    path: "/en/room/:slug",
    component: _4818287a,
    name: "room-slug___en"
  }, {
    path: "/en/search/:query?",
    component: _edf3d1d4,
    name: "search-query___en"
  }, {
    path: "/en/set/:id",
    component: _b3e4f054,
    name: "set-id___en"
  }, {
    path: "/product/catalog/:slug",
    component: _401bb606,
    name: "product-catalog-slug___pl"
  }, {
    path: "/profile/orders/:order",
    component: _5eb4441c,
    name: "profile-orders-order___pl"
  }, {
    path: "/profile/refunds/:refund",
    component: _31d09f23,
    name: "profile-refunds-refund___pl"
  }, {
    path: "/room/catalog/:slug",
    component: _575937cd,
    name: "room-catalog-slug___pl"
  }, {
    path: "/profile/orders/:order/refund",
    component: _3021aac4,
    name: "profile-orders-order-refund___pl"
  }, {
    path: "/blog/:slug",
    component: _14dc2c8a,
    name: "blog-slug___pl"
  }, {
    path: "/catalog/:query?",
    component: _54c8e4e9,
    name: "catalog-query___pl"
  }, {
    path: "/contacts/:id",
    component: _68b9fc2a,
    name: "contacts-id___pl"
  }, {
    path: "/en/:slug",
    component: _1211f190,
    name: "slug___en"
  }, {
    path: "/product/:id",
    component: _56c436a3,
    name: "product-id___pl"
  }, {
    path: "/room/:slug",
    component: _4818287a,
    name: "room-slug___pl"
  }, {
    path: "/search/:query?",
    component: _edf3d1d4,
    name: "search-query___pl"
  }, {
    path: "/set/:id",
    component: _b3e4f054,
    name: "set-id___pl"
  }, {
    path: "/",
    component: _13c892d8,
    name: "index___pl"
  }, {
    path: "/:slug",
    component: _1211f190,
    name: "slug___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
